import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class LifeInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Life Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Life Insurance is one of the most important ways to protect your family. If something happens to you, it is vital to ensure your family is taken care of. Contact us, your Baton Rouge, Louisiana based insurance agency, today!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Women running on a hill" />
        <Callout
          title="Life Insurance"
          content="Life Insurance is one of the most important ways to protect your family. If something happens to you, it is vital to ensure your family is taken care of. In the event of death, the designee(s) will receive the policy payout. Below are a list of things that people in Louisiana normally use life insurance to cover."
        />
        <div className="site-body page-content">
          <h2>Life Insurance Payouts are Usually Used to Cover</h2>
          <p>
          <ul>
          <li><strong>End-of-life expenses.</strong> This pays for funeral expenses.</li>
          <li><strong>Day-to-day bills.</strong> Car payments, car insurance, groceries, clothes and school supplies for the kids.</li>
          <li><strong>The Mortgage.</strong> Knowing that life insurance proceeds can be put toward paying the mortgage can offer your spouse the freedom to make decisions that are best for your family during a trying time.</li>
          <li><strong>Cosigned debts.</strong> Even if you’re unmarried, there may be a co-signer who would be responsible for the outstanding debt you left behind.</li>
          </ul>
          </p>
          <DidYouKnow
            fact="85 percent of consumers agree that most people need life insurance, yet just 62 percent say they have it."
            source="LIMRA’s Life Insurance Barometer Study, 2013"
          />
          <h2>What Life Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>While some life insurance policies have a suicide clause that allows for payment of benefits after the policy is over two years old, not all policies have this clause.</li>
          <li>Certain reckless endangerments are excluded in many policies.</li>
          </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Life Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default LifeInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "life-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-25.jpg" }) {
      ...mainBg
    }
  }
`;
